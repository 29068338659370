<template>
  <base-layout :title="title" :backLink="backLink" :reloadable="reloadable">
    <ion-list>
      <ion-item>
        <ion-label>From date</ion-label>
        <ion-datetime type="text" v-model="f_date"></ion-datetime>
      </ion-item>
    </ion-list>

    <ion-list>
      <ion-item>
        <ion-label>To date</ion-label>
        <ion-datetime type="text" v-model="t_date"></ion-datetime>
      </ion-item>
    </ion-list>

    <ion-button
      type="submit"
      expand="full"
      class="ion-margin-vertical"
      :href="downloadLink"
      :download="`sms_report_${from_date}_${to_date}`"
    >
      Download
    </ion-button>
  </base-layout>
</template>

<script>
import BaseLayout from "../../../Base/BaseLayout.vue";
import { IonList, IonItem, IonLabel, IonDatetime, IonButton } from "@ionic/vue";
import base_url from "../../../../apis/base_url";

import moment from "moment";

export default {
  components: {
    BaseLayout,
    IonList,
    IonItem,
    IonLabel,
    IonDatetime,
    IonButton,
  },
  data() {
    return {
      title: "SMS Report",
      backLink: "communication",
      reloadable: false,

      f_date: new Date().toISOString(),
      t_date: new Date().toISOString(),

      from_date: moment(this.f_date).format("YYYY-MM-DD"),
      to_date: moment(this.t_date).format("YYYY-MM-DD"),

      base_url,

      xhrError: {
        header: null,
        description: null,
      },
    };
  },

  watch: {
    f_date(new_date) {
      this.from_date = moment(new_date).format("YYYY-MM-DD");
    },
    t_date(new_date) {
      this.to_date = moment(new_date).format("YYYY-MM-DD");
    },
  },

  computed: {
    downloadLink() {
      return `${this.base_url}api/v1/sms-report?from_date=${this.from_date}&to_date=${this.to_date}&pat=${this.token}`;
    },

    token() {
      return this.$store.getters["auth/token"];
    },
  },
};
</script>

<style scoped></style>
